// src/components/SendEmailNode.tsx
import React, { useState, useEffect } from "react";
import { Position } from "react-flow-renderer";
import { NodeContainer, darkBlueInputStyle } from "./NodeContainer";
import NodeHandle from "./NodeHandle";
import NewEmailModal from "../modals/NewEmailModal";
import { EmailCredential } from "../../../types/flowTypes";
import { getEmailCredentials } from "../../../api/flowsService";
import { EmailCredentialDropdown, RecipientList } from "./EmailCredentialDropdown";
import { FileUploadField } from '../common/fileField/FileUploadField';

const SendEmailNode = ({
  id,
  data,
  onDelete,
  onUpdate,
}: {
  id: string;
  data: any;
  onDelete: (id: string) => void;
  onUpdate: (id: string, newData: any) => void;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [recipient, setRecipient] = useState(data["recipient"] || "");
  const [subject, setSubject] = useState(data["subject"] || "");
  const [plainContent, setPlainContent] = useState(data["content"] || "");
  const [htmlContent, setHtmlContent] = useState(data["html_content"] || "");
  const [useHtml, setUseHtml] = useState(data["use_html"] || false);
  const [recipients, setRecipients] = useState<string[]>(data["recipients"] || []);
  const [emailCredentials, setEmailCredentials] = useState<EmailCredential[]>([]);
  const [selectedCredential, setSelectedCredential] = useState<EmailCredential | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [handleColors, setHandleColors] = useState<{ [key: string]: string }>();
  const [handleAnimations, setHandleAnimations] = useState<{ [key: string]: string }>();
  const [attachments, setAttachments] = useState<string[]>(data["attachments"] || []);

  useEffect(() => {
    loadEmailCredentials();
  }, []);

  useEffect(() => {
    setHandleColors(data.handleColors || handleColors);
    setHandleAnimations(data.handleAnimations || handleAnimations);
  }, [data.handleColors, data.handleAnimations]);

  useEffect(() => {
    if (data.credentialId && emailCredentials.length > 0) {
      const savedCredential = emailCredentials.find(c => c.id === data.credentialId);
      if (savedCredential) {
        setSelectedCredential(savedCredential);
      }
    }
  }, [data.credentialId, emailCredentials]);

  const loadEmailCredentials = async () => {
    try {
      const credentials = await getEmailCredentials();
      setEmailCredentials(credentials);
    } catch (error) {
      console.error("Failed to load email credentials:", error);
    }
  };

  const updateNodeData = (newData: any) => {
    onUpdate(id, { ...data, ...newData });
  };

  useEffect(() => {
    updateNodeData({
      credentialId: selectedCredential?.id,
      sender: selectedCredential?.email_address,
      recipients,
      subject,
      content: plainContent,
      html_content: htmlContent,
      use_html: useHtml,
      attachments
    });
  }, [selectedCredential, recipients, subject, plainContent, htmlContent, useHtml, attachments]);

  const handleAddRecipient = () => {
    if (recipient.trim() && !recipients.includes(recipient.trim())) {
      setRecipients([...recipients, recipient.trim()]);
      setRecipient("");
    }
  };

  const handleDeleteRecipient = (index: number) => {
    const newRecipients = [...recipients];
    newRecipients.splice(index, 1);
    setRecipients(newRecipients);
  };

  const handleHoverText = (text: string) => {
    setTooltipText(text);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleAddEmailAccount = async (credential: EmailCredential) => {
    try {
      await loadEmailCredentials();
      
      setSelectedCredential(credential);
      setModalVisible(false);
      
      updateNodeData({
        credentialId: credential.id,
        sender: credential.email_address
      });
    } catch (error) {
      console.error("Error adding email account:", error);
    }
  };

  const handleEditCredential = (credential: EmailCredential) => {
    setSelectedCredential(credential);
    setModalVisible(true);
    setDropdownVisible(false);
  };

  const handleUploadComplete = (filePaths: string[]) => {
    setAttachments(filePaths);
    updateNodeData({ attachments: filePaths });
  };

  const handleUploadError = (error: string) => {
    console.error('Upload error:', error);
  };

  const renderEmailDropdown = () => (
    <div className="relative">
      <div
        className={`${darkBlueInputStyle} w-full cursor-pointer flex justify-between items-center`}
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        <span>{selectedCredential?.email_address || "Select Sender Account"}</span>
        <span className="ml-2">&#9660;</span>
      </div>
      
      {dropdownVisible && (
        <ul className="absolute left-0 mt-2 w-full bg-gray-800 text-white rounded-lg shadow-lg z-10">
          {emailCredentials.map((credential) => (
            <li key={credential.id} className="p-2 hover:bg-gray-700">
              <div className="flex justify-between items-center">
                <span className="cursor-pointer"
                  onClick={() => {
                    setSelectedCredential(credential);
                    setDropdownVisible(false);
                    updateNodeData({
                      credentialId: credential.id,
                      sender: credential.email_address
                    });
                  }}>
                  {credential.email_address}
                </span>
                <button
                  className="ml-2 px-2 py-1 bg-blue-500 rounded hover:bg-blue-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedCredential(credential);
                    setModalVisible(true);
                    setDropdownVisible(false);
                  }}
                >
                  Edit
                </button>
              </div>
            </li>
          ))}
          <li
            className="p-2 bg-green-500 hover:bg-green-400 cursor-pointer text-center"
            onClick={() => {
              setSelectedCredential(null);
              setModalVisible(true);
              setDropdownVisible(false);
            }}
          >
            Add Email
          </li>
        </ul>
      )}
    </div>
  );

  return (
    <NodeContainer
      id={id}
      className="bg-purple-700"
      onDelete={onDelete}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      <h3 className="text-lg font-semibold">Send Email</h3>
      
      <label>Sender Account:</label>
      {renderEmailDropdown()}

      <label className="block mt-2">Recipients:</label>
      <div className="flex items-center">
        <input
          type="email"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          placeholder="Enter recipient email"
          className={darkBlueInputStyle}
        />
        <button
          className="ml-2 p-2 bg-green-500 hover:bg-green-600 text-white rounded-md"
          onClick={handleAddRecipient}
        >
          Add
        </button>
      </div>
      
      <RecipientList
        recipients={recipients}
        onDelete={handleDeleteRecipient}
      />

      <label className="block mt-2">Subject:</label>
      <input
        type="text"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        placeholder="Email Subject"
        className={darkBlueInputStyle}
      />

      <div className="flex items-center mt-2">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={useHtml}
            onChange={(e) => setUseHtml(e.target.checked)}
            className="form-checkbox h-4 w-4 text-blue-600"
          />
          <span className="ml-2">Use HTML Content</span>
        </label>
      </div>

      <label className="block mt-2">Plain Text Content:</label>
      <textarea
        placeholder="Plain Text Content"
        value={plainContent}
        onChange={(e) => setPlainContent(e.target.value)}
        className={`${darkBlueInputStyle} min-h-[100px]`}
      />

      {useHtml && (
        <>
          <label className="block mt-2">HTML Content:</label>
          <textarea
            placeholder="HTML Content"
            value={htmlContent}
            onChange={(e) => setHtmlContent(e.target.value)}
            className={`${darkBlueInputStyle} min-h-[100px] font-mono`}
          />
        </>
      )}

      <label className="block mt-4 mb-2">Attachments:</label>
      <FileUploadField
        directoryKey="attachments"
        maxFiles={10}
        maxSizeInMB={25}
        acceptedTypes="*/*"
        onUploadComplete={handleUploadComplete}
        onError={handleUploadError}
        existingFiles={attachments}
        className="upload-button"
      />

      <NodeHandle
        id="signal-in"
        type="target"
        position={Position.Left}
        label="Send Signal"
        style={{ top: "20%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Send email signal from the previous block.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="email-in"
        type="target"
        position={Position.Left}
        label="Recipient"
        style={{ top: "40%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Optional input: recipient email address.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="text-in"
        type="target"
        position={Position.Left}
        label="Content"
        style={{ top: "60%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Optional input: email content with subject and body.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="file-in"
        type="target"
        position={Position.Left}
        label="File"
        style={{ top: "80%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Optional input: file attachments.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="signal-out"
        type="source"
        position={Position.Right}
        label="Sent"
        style={{ top: "50%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Emails sent successfully.")}
        onLeave={handleMouseLeave}
      />
      <NodeHandle
        id="error-out"
        type="source"
        position={Position.Right}
        label="Error"
        style={{ top: "80%" }}
        handleColors={handleColors}
        handleAnimations={handleAnimations}
        onHover={() => handleHoverText("Emails that failed to send.")}
        onLeave={handleMouseLeave}
      />
      {modalVisible && (
        <NewEmailModal
          onClose={() => setModalVisible(false)}
          onAddEmail={handleAddEmailAccount}
          editCredential={selectedCredential || undefined}
        />
      )}
    </NodeContainer>
  );
};

export default SendEmailNode;