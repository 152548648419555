import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  RiDatabase2Line, RiNotification3Line, RiRobotLine,
  RiFlowChart, RiCodeLine, RiMailLine, RiTimeLine,
  RiFileTextLine, RiDownload2Line, RiPlayCircleLine,
  RiShieldCheckLine, RiCustomerService2Line, RiSettings4Line,
  RiBarChart2Line,
} from "react-icons/ri";
import { motion, useScroll, useTransform } from 'framer-motion';

const HomePage: React.FC = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);

  // Add ROI metrics
  const roiMetrics = [
    { metric: "40%", description: "Average Cost Reduction" },
    { metric: "3x", description: "Faster Workflow Execution" },
    { metric: "99.9%", description: "System Uptime" },
  ];

  // Add social proof
  const testimonials = [
    {
      quote: "SignalFlow reduced our operational costs by 40% while improving efficiency",
      author: "CTO, Fortune 500 Company",
      company: "Enterprise Tech",
    },
    {
      quote: "The automation capabilities have transformed how we handle data processing",
      author: "Head of Operations",
      company: "Global Finance Corp",
    },
    {
      quote: "Best-in-class workflow automation with enterprise-grade reliability",
      author: "Director of Technology",
      company: "Leading Healthcare Provider",
    },
  ];

  // Slider settings from your original code
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "15%",
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    afterChange: () => {
      const slides = document.querySelectorAll<HTMLElement>(".slick-slide");
      slides.forEach((slide) => {
        slide.style.transform = "scale(0.85)";
        slide.style.opacity = "0.7";
        slide.style.filter = "none";
        if (slide.classList.contains("slick-center")) {
          slide.style.transform = "scale(1)";
          slide.style.opacity = "1";
        }
      });
    },
  };

  const automationFeatures = [
    {
      icon: <RiFlowChart className="text-4xl" />,
      title: "Visual Flow Builder",
      description: "Create complex workflows with our intuitive drag-and-drop interface",
      details: ["Custom block creation", "Visual flow mapping", "Real-time preview"]
    },
    {
      icon: <RiTimeLine className="text-4xl" />,
      title: "Time Trigger System",
      description: "Schedule workflows with precision timing",
      details: ["Cron expressions", "Delay handling", "Time-zone support"]
    },
    {
      icon: <RiMailLine className="text-4xl" />,
      title: "Email Integration",
      description: "Advanced email monitoring and processing",
      details: ["Keyword tracking", "Attachment handling", "Smart filtering"]
    }
  ];

  return (
    <div className="bg-gradient-to-b from-[#15061a] to-[#1e1134] min-h-screen">
      {/* Hero Section with Video Background */}
      <div className="relative min-h-screen">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="SIgnal_flow.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        
        {/* Hero Content */}
        <div className="relative z-10 ">
          <header className="text-center pt-20 pb-1">
            <motion.div
              className="text-center mb-8"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-6xl font-bold mb-4 p-2 bg-clip-text text-transparent bg-default-gradient">
                Signal Flow
              </h1>
              <p className="max-w-3xl mx-auto text-2xl text-gray-300 mb-4">
                Enterprise-Grade Workflow Automation Platform
              </p>
              {/* <p className="max-w-2xl mx-auto text-xl text-purple-300 mb-8">
                Trusted by Fortune 500 companies to reduce operational costs and accelerate business growth
              </p> */}
              <div className="flex justify-center gap-4 mb-8">
                <a 
                  href="https://calendly.com/terrabitecrew/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-8 py-4 bg-purple-600 rounded-full hover:bg-purple-700 transition flex items-center text-white text-lg font-semibold"
                >
                  Schedule a Demo
                </a>
                <a 
                  href="https://www.youtube.com/watch?v=sVH5jF4vRAE"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-8 py-4 bg-transparent border-2 border-purple-600 rounded-full hover:bg-purple-600/20 transition flex items-center text-white text-lg"
                >
                  <RiPlayCircleLine className="mr-2" /> Watch Demo
                </a>
              </div>

              {/* ROI Metrics */}
              {/* <div className="grid grid-cols-3 gap-8 max-w-4xl mx-auto mb-12">
                {roiMetrics.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="text-center"
                  >
                    <div className="text-4xl font-bold text-purple-400 mb-2">{item.metric}</div>
                    <div className="text-gray-300">{item.description}</div>
                  </motion.div>
                ))}
              </div> */}
            </motion.div>
          </header>

          {/* Social Proof Section */}
          {/* <div className="max-w-6xl mx-auto px-4 mb-16">
            <div className="grid md:grid-cols-3 gap-6">
              {testimonials.map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-[#2a1b3d]/80 p-6 rounded-xl backdrop-blur-sm"
                >
                  <p className="text-gray-300 mb-4">"{testimonial.quote}"</p>
                  <p className="text-purple-400 font-semibold">{testimonial.author}</p>
                  <p className="text-gray-400">{testimonial.company}</p>
                </motion.div>
              ))}
            </div>
          </div> */}

          {/* Slider Section */}
          <div className="relative mb-16 max-w-4xl mx-auto ">
            <Slider {...sliderSettings}>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="Visual Workflow Builder"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="Visual Workflow Builder"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="AI Automation"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
              <div>
                <img
                  src="/welcome_banner.jpg"
                  alt="Platform Integrations"
                  className="rounded-lg"
                  style={{ margin: "0 auto", borderRadius: "20px" }}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* Add Trust Badges Section before Platform Capabilities */}
      {/* <section className="py-12 px-4 bg-[#1a0b26]">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center">
            <motion.img
              src="/trust-badge-1.png"
              alt="Security Certification"
              className="h-16 opacity-70 hover:opacity-100 transition"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 0.7 }}
              viewport={{ once: true }}
            /> */}
            {/* Add placeholder images for other trust badges */}
            {/* <motion.div className="h-16 w-32 bg-[#2a1b3d] rounded opacity-70" />
            <motion.div className="h-16 w-32 bg-[#2a1b3d] rounded opacity-70" />
            <motion.div className="h-16 w-32 bg-[#2a1b3d] rounded opacity-70" />
          </div>
        </div>
      </section> */}

      {/* Platform Capabilities Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Platform Capabilities</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Powered by Apache Airflow and modern AI technologies, SignalFlow 
              delivers enterprise-grade automation for your business needs
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {automationFeatures.map((feature, index) => (
              <motion.div
                key={index}
                className="bg-[#2a1b3d] p-6 rounded-xl hover:bg-[#351d4d] transition cursor-pointer"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="text-purple-400 mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">{feature.title}</h3>
                <p className="text-gray-400 mb-4">{feature.description}</p>
                <ul className="text-gray-500">
                  {feature.details.map((detail, i) => (
                    <li key={i} className="mb-1">• {detail}</li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      

      {/* AI Capabilities Section */}
      <section className="py-20 bg-[#1a0b26] px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold text-white"><span className="bg-clip-text text-transparent bg-default-gradient">Coming Soon:</span> AI-Enhanced Automation</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Leverage the power of AI to optimize your workflows and automate complex decisions
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="space-y-6"
            >
              <div className="bg-[#2a1b3d] p-6 rounded-xl">
                <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-default-gradient">Intelligent Decision Making</h3>
                <p className="text-gray-400">AI agents that analyze patterns and make smart routing decisions based on historical data and real-time conditions.</p>
              </div>
              <div className="bg-[#2a1b3d] p-6 rounded-xl">
                <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-default-gradient">Pattern Recognition</h3>
                <p className="text-gray-400">Advanced algorithms identify trends and anomalies in your workflows, suggesting optimizations automatically.</p>
              </div>
              <div className="bg-[#2a1b3d] p-6 rounded-xl">
                <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-default-gradient">Predictive Analytics</h3>
                <p className="text-gray-400">Forecast potential issues and bottlenecks before they occur, enabling proactive optimization.</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="relative"
            >
              <img 
                src="/ai.jpeg" 
                alt="AI Workflow"
                className="rounded-xl shadow-2xl"
              />
              <div className="absolute -bottom-8 right-0 bg-purple-600 p-4 rounded-xl">
                <p className="text-sm font-semibold">Coming Soon: GPT-4 Integration</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Core Features Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Core System Features</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Built on Apache Airflow with enterprise-grade reliability and scalability
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Action Flow System</h3>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Visual block-based workflow builder
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Custom block development
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Real-time workflow monitoring
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Drag-and-drop interface
                </li>
              </ul>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Notification System</h3>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Multi-channel notifications
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Smart alert routing
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Priority-based delivery
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Custom notification rules
                </li>
              </ul>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Integration Hub</h3>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Email system integration
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  API connectivity
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Database connections
                </li>
                <li className="flex items-center">
                  <span className="mr-2">•</span>
                  Custom webhooks
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-20 bg-[#1a0b26] px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Real-World Applications</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Discover how SignalFlow transforms business operations across industries
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Data Scraping & Processing</h3>
              <div className="space-y-3 text-gray-400">
                <p>• Automated web scraping with intelligent retry mechanisms</p>
                <p>• Real-time data validation and cleaning</p>
                <p>• Structured data storage and processing</p>
                <p>• Custom scraping rules and patterns</p>
              </div>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">System Monitoring</h3>
              <div className="space-y-3 text-gray-400">
                <p>• Real-time performance monitoring</p>
                <p>• Automated alert systems</p>
                <p>• Resource usage tracking</p>
                <p>• Predictive maintenance</p>
              </div>
            </motion.div>

            <motion.div
              className="bg-[#2a1b3d] p-6 rounded-xl"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-semibold mb-4 bg-clip-text text-transparent bg-default-gradient">Workflow Automation</h3>
              <div className="space-y-3 text-gray-400">
                <p>• Custom DAG creation and management</p>
                <p>• Task dependency handling</p>
                <p>• Error recovery and retry logic</p>
                <p>• Performance optimization</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Technical Overview Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Technical Architecture</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Built on industry-leading technologies for maximum reliability and performance
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="bg-[#2a1b3d] p-8 rounded-xl"
            >
              <h3 className="text-2xl font-semibold mb-6 bg-clip-text text-transparent bg-default-gradient">Core Technologies</h3>
              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <div className="bg-purple-600 p-2 rounded-lg">
                    <RiCodeLine className="text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1 bg-clip-text text-transparent bg-default-gradient ">Apache Airflow</h4>
                    <p className="text-gray-400">Enterprise-grade workflow orchestration</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="bg-purple-600 p-2 rounded-lg">
                    <RiDatabase2Line className="text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1 bg-clip-text text-transparent bg-default-gradient">PostgreSQL & Redis</h4>
                    <p className="text-gray-400">Robust data storage and caching</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="bg-purple-600 p-2 rounded-lg">
                    <RiRobotLine className="text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1 bg-clip-text text-transparent bg-default-gradient">AI Integration</h4>
                    <p className="text-gray-400">Advanced machine learning capabilities</p>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="bg-[#2a1b3d] p-8 rounded-xl"
            >
              <h3 className="text-2xl font-semibold mb-6 bg-clip-text text-transparent bg-default-gradient">System Architecture</h3>
              <div className="space-y-4 text-gray-400">
                <p>• Microservices-based architecture</p>
                <p>• Containerized deployment with Docker</p>
                <p>• Horizontal scaling capabilities</p>
                <p>• Real-time event processing</p>
                <p>• Automated backup and recovery</p>
                <p>• Multi-tenant support</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-20 bg-[#1a0b26] px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-default-gradient">Flexible Enterprise Solutions</h2>
            <p className="text-gray-400 max-w-2xl mx-auto mb-4">
              Scalable pricing designed for businesses of all sizes
            </p>
            <div className="mt-4 bg-purple-600/20 border border-purple-600/40 rounded-lg p-6 max-w-3xl mx-auto">
              <h3 className="text-xl font-semibold text-purple-300 mb-3">🚀 Early Access Program</h3>
              <p className="text-gray-300 mb-4">
                Join our early access program and get exclusive benefits:
              </p>
              <ul className="text-gray-300 mb-4 space-y-2">
                <li>• Priority access to new features</li>
                <li>• Dedicated support team</li>
                <li>• Custom implementation assistance</li>
                <li>• Flexible pricing options</li>
              </ul>
              <div className="flex justify-center gap-4">
                <a 
                  href="mailto:solutions@signalflow.live" 
                  className="px-6 py-3 bg-purple-600 rounded-lg hover:bg-purple-700 transition text-white font-semibold"
                >
                  Contact Sales Team
                </a>
                <a 
                  href="https://calendly.com/terrabitecrew/30min" 
                  className="px-6 py-3 bg-transparent border border-purple-600 rounded-lg hover:bg-purple-600/20 transition text-white"
                >
                  Schedule Demo
                </a>
              </div>
            </div>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {/* Enterprise Starter */}
            <motion.div
              className="bg-[#2a1b3d] p-8 rounded-xl border-2 border-purple-600/40"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-center mb-6">
                <div className="text-sm text-purple-400 mb-2">Enterprise Starter</div>
                <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">Custom Pricing</h3>
                <p className="text-gray-400">For growing teams</p>
              </div>
              <ul className="space-y-3 text-gray-400 mb-6">
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Up to 25 workflows
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Standard support
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Basic integrations
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  30-day data retention
                </li>
              </ul>
              <button className="w-full py-3 bg-purple-600 rounded-lg hover:bg-purple-700 transition font-semibold">
                <a href="mailto:solutions@signalflow.live">Contact Sales</a>
              </button>
            </motion.div>

            {/* Enterprise Plus */}
            <motion.div
              className="bg-[#2a1b3d] p-8 rounded-xl border-2 border-purple-600/40 transform md:scale-105 md:-translate-y-4"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <span className="bg-purple-600 text-white px-4 py-1 rounded-full text-sm">Most Popular</span>
              </div>
              <div className="text-center mb-6">
                <div className="text-sm text-purple-400 mb-2">Enterprise Plus</div>
                <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">Custom Pricing</h3>
                <p className="text-gray-400">For scaling operations</p>
              </div>
              <ul className="space-y-3 text-gray-400 mb-6">
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Unlimited workflows
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Priority support
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Advanced integrations
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  90-day data retention
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Custom development
                </li>
              </ul>
              <button className="w-full py-3 bg-purple-600 rounded-lg hover:bg-purple-700 transition font-semibold">
                <a href="mailto:solutions@signalflow.live">Contact Sales</a>
              </button>
            </motion.div>

            {/* Enterprise Custom */}
            <motion.div
              className="bg-[#2a1b3d] p-8 rounded-xl border-2 border-purple-600/40"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-center mb-6">
                <div className="text-sm text-purple-400 mb-2">Enterprise Custom</div>
                <h3 className="text-2xl font-semibold mb-2 bg-clip-text text-transparent bg-default-gradient">Custom Pricing</h3>
                <p className="text-gray-400">For large organizations</p>
              </div>
              <ul className="space-y-3 text-gray-400 mb-6">
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Everything in Enterprise Plus
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  24/7 dedicated support
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Custom SLA
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  On-premise deployment
                </li>
                <li className="flex items-center">
                  <span className="mr-2 text-purple-400">✓</span>
                  Unlimited data retention
                </li>
              </ul>
              <button className="w-full py-3 bg-purple-600 rounded-lg hover:bg-purple-700 transition font-semibold">
                <a href="mailto:solutions@signalflow.live">Contact Sales</a>
              </button>
            </motion.div>
          </div>

          {/* Enterprise Benefits */}
          <div className="mt-16 grid md:grid-cols-4 gap-8">
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-purple-400 text-4xl mb-4">
                <RiShieldCheckLine />
              </div>
              <h4 className="text-xl font-semibold mb-2 text-white">Enterprise Security</h4>
              <p className="text-gray-400">Bank-grade security and compliance features</p>
            </motion.div>

            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-purple-400 text-4xl mb-4">
                <RiCustomerService2Line />
              </div>
              <h4 className="text-xl font-semibold mb-2 text-white">Dedicated Support</h4>
              <p className="text-gray-400">Personal account manager and technical support</p>
            </motion.div>

            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-purple-400 text-4xl mb-4">
                <RiSettings4Line />
              </div>
              <h4 className="text-xl font-semibold mb-2 text-white">Custom Development</h4>
              <p className="text-gray-400">Tailored solutions for your specific needs</p>
            </motion.div>

            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <div className="text-purple-400 text-4xl mb-4">
                <RiBarChart2Line />
              </div>
              <h4 className="text-xl font-semibold mb-2 text-white">Scalable Infrastructure</h4>
              <p className="text-gray-400">Enterprise-grade reliability and performance</p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Add Floating CTA */}
      <div className="fixed bottom-8 right-8 z-50">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 2 }}
        >
          <a
            href="https://calendly.com/terrabitecrew/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition shadow-lg"
          >
            <span className="animate-pulse">🎯</span>
            Schedule Demo
          </a>
        </motion.div>
      </div>

      {/* Continue with additional sections... */}
    </div>
  );
};

export default HomePage;
