import { store } from '../app/store';
import axios from './axios';

export interface UploadResponse {
    status: 'success' | 'error';
    file_paths: string[];
    file_urls: string[];
    message?: string;
}

export interface FileMetadata {
    path: string;
    url: string;
    name: string;
    size: number;
    type: string;
}

// Upload files to a specific directory
export const uploadFiles = async (
    directoryKey: 'x_media' | 'profile_images' | 'attachments' | 'temp',
    files: File[]
): Promise<UploadResponse> => {
    try {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        const response = await axios.post<UploadResponse>(
            `/storage/upload/${directoryKey}/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 60000,
            }
        );
        return response.data;
    } catch (error: any) {
        if (error.response?.data?.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Failed to upload files');
    }
};

// Delete a file by its path
export const deleteFile = async (filePath: string): Promise<void> => {
    try {
        await axios.delete(`/storage/files/${encodeURIComponent(filePath)}/`);
    } catch (error: any) {
        if (error.response?.data?.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Failed to delete file');
    }
};

// Get file metadata
export const getFileMetadata = async (filePath: string): Promise<FileMetadata> => {
    try {
        const response = await axios.get<FileMetadata>(
            `/storage/files/${encodeURIComponent(filePath)}/metadata/`
        );
        return response.data;
    } catch (error: any) {
        if (error.response?.data?.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Failed to get file metadata');
    }
};

// Get file URL
export const getFileUrl = async (filePath: string): Promise<string> => {
    try {
        const response = await axios.get<{ url: string }>(
            `/storage/files/${encodeURIComponent(filePath)}/url/`
        );
        return response.data.url;
    } catch (error: any) {
        if (error.response?.data?.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Failed to get file URL');
    }
};

// Upload multiple files with progress tracking
export const uploadFilesWithProgress = async (
    directoryKey: 'x_media' | 'profile_images' | 'attachments' | 'temp',
    files: File[],
    onProgress?: (progress: number) => void
): Promise<UploadResponse> => {
    try {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        const response = await axios.post<UploadResponse>(
            `/storage/upload/${directoryKey}/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 60000,
                onUploadProgress: (progressEvent) => {
                    if (onProgress && progressEvent.total) {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        onProgress(progress);
                    }
                },
            }
        );
        return response.data;
    } catch (error: any) {
        if (error.response?.data?.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Failed to upload files');
    }
};

// Check if file exists
export const checkFileExists = async (filePath: string): Promise<boolean> => {
    try {
        await axios.head(`/storage/files/${encodeURIComponent(filePath)}/`, {
            timeout: 60000,
        });
        return true;
    } catch (error) {
        return false;
    }
};

// Get file preview URL using the same axios instance
export const getFilePreviewUrl = (filePath: string): string => {
    const token = store.getState().user.token; // Get token from Redux store
    return `${axios.defaults.baseURL}/storage/file/${filePath}?token=${token}`;
};