// src/components/NewEmailModal.tsx
import React, { useState } from "react";
import { createEmailCredential, testEmailConnection, updateEmailCredential } from "../../../api/flowsService";
import { EmailCredential } from "../../../types/flowTypes";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from react-icons

type NewEmailModalProps = {
  onClose: () => void;
  onAddEmail: (newEmail: EmailCredential) => void;
  editCredential?: EmailCredential;
};

const NewEmailModal = ({ onClose, onAddEmail, editCredential }: NewEmailModalProps) => {
  const [formData, setFormData] = useState({
    email_address: editCredential?.email_address || "",
    email_password: "",
    provider_type: editCredential?.provider_type || "gmail",
    is_active: editCredential?.is_active ?? true
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [testStatus, setTestStatus] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [imapConfirmed, setImapConfirmed] = useState(false);

  const providers = [
    { value: 'gmail', label: 'Gmail' },
    // { value: 'outlook', label: 'Outlook' },
    // { value: 'yahoo', label: 'Yahoo' },
    // { value: 'aol', label: 'AOL' },
    // { value: 'icloud', label: 'iCloud' },
    // { value: 'other', label: 'Other' }
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    
    try {
      let credential;
      if (editCredential) {
        credential = await updateEmailCredential(editCredential.id, formData);
      } else {
        credential = await createEmailCredential(formData);
      }
      
      // try {
      //   const testResult = await testEmailConnection(credential.id);
        
      //   if (testResult.status === 'success') {
      //     onAddEmail(credential);
      //     onClose();
      //   } else {
      //     setError("Connection test failed: " + testResult.message);
      //   }
      // } catch (testError: any) {
      //   setError("Failed to test connection: " + (testError.message || "Unknown error"));
      // }
      onAddEmail(credential);
    } catch (err: any) {
      if (err.response?.data?.code === "duplicate_email") {
        setError("This email address is already registered. Please use a different email or edit the existing one.");
      } else {
        setError(err.response?.data?.error || "Failed to add email credential");
      }
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="p-6 bg-gray-900 rounded-lg shadow-lg w-96">
        <h3 className="text-lg font-semibold mb-4">
          {editCredential ? 'Edit Email Account' : 'Add Email Account'}
        </h3>
        
        {error && (
          <div className="mb-4 p-2 bg-red-500 text-white rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">Email Address:</label>
            <input
              type="email"
              name="email_address"
              value={formData.email_address}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md bg-gray-700"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2">
              {formData.provider_type === 'gmail' ? 'App Password:' : 'Password:'}
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="email_password"
                value={formData.email_password}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md bg-gray-700 pr-10"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-900 hover:text-gray-700 focus:outline-none transition-colors duration-200"
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5" />
                ) : (
                  <FaEye className="h-5 w-5" />
                )}
                <span className="sr-only">
                  {showPassword ? "Hide password" : "Show password"}
                </span>
              </button>
            </div>
            {formData.provider_type === 'gmail' && (
              <p className="text-sm text-yellow-500 mt-1">
                Gmail requires an App Password. Regular passwords won't work. 
                <a 
                  href="https://support.google.com/accounts/answer/185833?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 ml-1"
                >
                  How to generate an App Password
                </a>
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="block mb-2">Provider:</label>
            <select
              name="provider_type"
              value={formData.provider_type}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md bg-gray-700"
              required
            >
              {providers.map(provider => (
                <option key={provider.value} value={provider.value}>
                  {provider.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="is_active"
                checked={formData.is_active}
                onChange={handleInputChange}
                className="mr-2"
              />
              Active
            </label>
          </div>

          {formData.provider_type === 'gmail' && (
            <div className="mb-4 space-y-2">
              <p className="text-sm text-yellow-500">Gmail requires two settings:</p>
              <ol className="list-decimal list-inside text-sm space-y-1 text-gray-300">
                <li>An App Password (see above)</li>
                <li>IMAP access enabled in Gmail settings:
                  <a 
                    href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300 ml-1"
                  >
                    Enable IMAP
                  </a>
                </li>
              </ol>
              
              <label className="flex items-center mt-2 text-sm">
                <input
                  type="checkbox"
                  checked={imapConfirmed}
                  onChange={(e) => setImapConfirmed(e.target.checked)}
                  className="mr-2"
                  required
                />
                I confirm that I have enabled IMAP access in Gmail settings
              </label>
            </div>
          )}

          <div className="flex justify-between mt-4">
            <button
              type="submit"
              disabled={isSubmitting || (formData.provider_type === 'gmail' && !imapConfirmed)}
              className="p-2 bg-green-500 text-white rounded-md disabled:bg-green-700 disabled:cursor-not-allowed"
            >
              {isSubmitting ? "Adding..." : (editCredential ? "Update Email" : "Add Email")}
            </button>
            <button
              type="button"
              onClick={onClose}
              disabled={isSubmitting}
              className="p-2 bg-red-500 text-white rounded-md disabled:opacity-50"
            >
              Cancel
            </button>
          </div>
        </form>

        {testStatus && (
          <div className={`mt-4 p-2 rounded ${
            testStatus.includes('success') ? 'bg-green-500' : 'bg-red-500'
          } text-white`}>
            {testStatus}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewEmailModal;