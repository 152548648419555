// src/components/PublishXNode.tsx
import React, { useState, useEffect } from "react";
import { Position } from "react-flow-renderer";
import { NodeContainer, darkBlueInputStyle } from "./NodeContainer";
import NodeHandle from "./NodeHandle";
import NewXAccountModal from "../modals/NewXAccountModal";
import { XAccount } from "../../../types/flowTypes";
import { getXAccounts, deleteXAccount } from "../../../api/flowsService";
import { FileUploadField } from '../common/fileField/FileUploadField';

const PublishXNode = ({
  id,
  data,
  onDelete,
  onUpdate,
}: {
  id: string;
  data: any;
  onDelete: (id: string) => void;
  onUpdate: (id: string, newData: any) => void;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [content, setContent] = useState(data.content || "");
  const [accounts, setAccounts] = useState<XAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<XAccount | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [replyPermissions, setReplyPermissions] = useState(data.replyPermissions || "Everyone");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const [handleColors, setHandleColors] = useState<{ [key: string]: string }>();
  const [handleAnimations, setHandleAnimations] = useState<{ [key: string]: string }>();
  const [accountToEdit, setAccountToEdit] = useState<XAccount | null>(null);
  const [mediaFiles, setMediaFiles] = useState<string[]>(data.mediaFiles || []);

  useEffect(() => {
    setHandleColors(data.handleColors || handleColors);
    setHandleAnimations(data.handleAnimations || handleAnimations);
  }, [data.handleColors, data.handleAnimations]);

  useEffect(() => {
    loadAccounts();
  }, []);

  const loadAccounts = async () => {
    setIsLoading(true);
    try {
      const fetchedAccounts = await getXAccounts();
      setAccounts(fetchedAccounts);
      
      if (data.accountId && fetchedAccounts.length > 0) {
        const savedAccount = fetchedAccounts.find(acc => acc.id === data.accountId);
        if (savedAccount) {
          setSelectedAccount(savedAccount);
        }
      }
    } catch (err) {
      setError("Failed to load X accounts");
    } finally {
      setIsLoading(false);
    }
  };

  const handleHoverText = (text: string) => {
    setTooltipText(text);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleAddAccount = async (account: XAccount) => {
    try {
      await loadAccounts();
      
      setSelectedAccount(account);
      setModalVisible(false);
      
      updateNodeData({
        username: account.username
      });
    } catch (error) {
      console.error("Error adding X account:", error);
    }
  };

  const handleDeleteAccount = async (accountId: string) => {
    if (window.confirm("Are you sure you want to delete this account?")) {
      try {
        await deleteXAccount(accountId);
        setAccounts(prev => prev.filter(acc => acc.id !== accountId));
        if (selectedAccount?.id === accountId) {
          setSelectedAccount(null);
        }
      } catch (err) {
        setError("Failed to delete account");
      }
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleSelectAccount = (account: XAccount) => {
    setSelectedAccount(account);
    setDropdownVisible(false);
    updateNodeData({
      username: account.username
    });
  };

  const handleEditClick = (account: XAccount, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent dropdown from closing
    setAccountToEdit(account);
    setModalVisible(true);
  };

  const updateNodeData = (newData: any) => {
    onUpdate(id, { ...data, ...newData });
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    updateNodeData({ content: newContent });
  };

  const handleReplyPermissionsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPermissions = e.target.value;
    setReplyPermissions(newPermissions);
    updateNodeData({ replyPermissions: newPermissions });
  };

  useEffect(() => {
    updateNodeData({
      content,
      accountId: selectedAccount?.id,
      replyPermissions,
      username: selectedAccount?.username,
    });
  }, [content, selectedAccount, replyPermissions]);

  const handleUploadComplete = (filePaths: string[]) => {
    setMediaFiles(filePaths);
    updateNodeData({ mediaFiles: filePaths });
  };

  const handleUploadError = (error: string) => {
    setError(error);
  };

  return (
    <NodeContainer
      id={id}
      className="bg-[#763FB2]"
      onDelete={onDelete}
      showTooltip={showTooltip}
      tooltipText={tooltipText}
    >
      <h3 className="text-lg font-semibold">Publish on X</h3>
      <label>Choose Account:</label>
      <div className="relative">
        <div
          className={`${darkBlueInputStyle} w-full cursor-pointer flex justify-between items-center`}
          onClick={handleDropdownToggle}
        >
          {selectedAccount ? selectedAccount.username : "Select an X Account"}
          <span className="ml-2">&#9660;</span>
        </div>
        {dropdownVisible && (
          <ul className="absolute left-0 mt-2 w-full bg-gray-800 text-white rounded-lg shadow-lg z-10">
            {accounts.map((account) => (
              <li
                key={account.id}
                className="p-2 hover:bg-gray-700 cursor-pointer flex justify-between items-center"
              >
                <span onClick={() => handleSelectAccount(account)}>
                  {account.username}
                </span>
                <div className="flex gap-2">
                  <button
                    onClick={(e) => handleEditClick(account, e)}
                    className="text-blue-400 hover:text-blue-300"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteAccount(account.id!)}
                    className="text-red-400 hover:text-red-300"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
            <li
              className="p-2 bg-green-500 hover:bg-green-400 cursor-pointer text-center"
              onClick={() => {
                setAccountToEdit(null); // Reset account to edit when adding new
                setModalVisible(true);
                setDropdownVisible(false);
              }}
            >
              Add Account
            </li>
          </ul>
        )}
      </div>
      <label className="block mt-2">Content:</label>
      <textarea
        placeholder="Text content (up to 280 characters)"
        value={content}
        onChange={handleContentChange}
        className={darkBlueInputStyle}
        maxLength={280}
      />
      <label className="block mt-4 mb-2 text-sm font-medium">Media:</label>
      <FileUploadField
        directoryKey="x_media"
        maxFiles={4}
        maxSizeInMB={15}
        acceptedTypes="image/*,video/*"
        onUploadComplete={handleUploadComplete}
        onError={handleUploadError}
        className="upload-button"
        existingFiles={mediaFiles}
        // baseUrl={process.env.REACT_APP_BACKEND_URL + '/media/' || 'http://localhost:8000/media/'}
      />
      <label className="block mt-2">Who Can Reply:</label>
      <select
        value={replyPermissions}
        onChange={handleReplyPermissionsChange}
        className={darkBlueInputStyle}
        >
          <option value="Everyone">Everyone</option>
          <option value="People you follow">People you follow</option>
          <option value="Only people you mention">Only people you mention</option>
        </select>
  
        <NodeHandle
          id="signal-in"
          type="target"
          position={Position.Left}
          label="Send Signal"
          style={{ top: "20%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Start publishing signal from the previous block.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="text-in"
          type="target"
          position={Position.Left}
          label="Content"
          style={{ top: "40%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Optional input: text content.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="file-in"
          type="target"
          position={Position.Left}
          label="Media"
          style={{ top: "60%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Optional input: media files.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="signal-out"
          type="source"
          position={Position.Right}
          label="Published"
          style={{ top: "50%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Content published successfully.")}
          onLeave={handleMouseLeave}
        />
  
        <NodeHandle
          id="error-out"
          type="source"
          position={Position.Right}
          label="Error"
          style={{ top: "80%", transform: "translateY(-50%)" }}
          handleColors={handleColors}
          handleAnimations={handleAnimations}
          onHover={() => handleHoverText("Publishing content failed.")}
          onLeave={handleMouseLeave}
        />
        {modalVisible && (
          <NewXAccountModal
            onClose={() => {
              handleModalClose();
              setAccountToEdit(null);
            }}
            onAddAccount={handleAddAccount}
            existingAccount={accountToEdit || undefined}
          />
        )}
      </NodeContainer>
    );
  };
  
  export default PublishXNode;