import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout, selectUser } from "../features/user/userSlice";
import NotificationDropdown from "./NotificationDoprown";
import { RiSettings3Line, RiLogoutBoxRLine, RiMenu3Line, RiCloseLine, RiArrowRightLine } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

// Google Analytics tracking function
const trackNavigation = (actionName: string) => {
  ReactGA.event({
    category: 'Navigation',
    action: actionName,
  });
};

const Header: React.FC = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(!user ? 1 : 0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dropdownRefDesktop = useRef<HTMLDivElement | null>(null);
  const dropdownRefPhone = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMobileMenu = () => setShowMobileMenu(!showMobileMenu);

  // Enhanced navigation items with tracking
  const navigationItems = [
    { path: "/", label: "Home" },
    { path: "/custom-dashboard", label: "Dashboard" },
    { path: "/automation-builder", label: "Action Flow" },
    { path: "/create-alerts", label: "Alerts" },
  ];

  // Enhanced tracking for user actions
  const handleNavClick = (path: string, label: string) => {
    trackNavigation(`Click_${label.replace(' ', '_')}`);
    toggleMobileMenu();
    navigate(path);
  };

  const handleLoginClick = () => {
    trackNavigation('Click_Login');
    toggleMobileMenu();
    navigate('/login');
  };

  const handleRegisterClick = () => {
    trackNavigation('Click_Register');
    toggleMobileMenu();
    navigate('/register');
  };

  const handleLogout = () => {
    trackNavigation('Click_Logout');
    toggleDropdown();
    dispatch(logout());
    navigate("/login");
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications && !user) {
      setUnreadNotifications(0);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        dropdownRefDesktop.current &&
        dropdownRefPhone.current &&
        !dropdownRefDesktop.current.contains(event.target) &&
        !dropdownRefPhone.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefDesktop, dropdownRefPhone]);

  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth < 768);
    updateIsMobile(); // Initial check
    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const sidebarVariants = {
    hidden: {
      x: "-100%",
      opacity: 0,
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
  };

  // Add structured data for navigation
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SiteNavigationElement",
    "name": "SignalFlow Navigation",
    "hasPart": [
      {
        "@type": "WebPage",
        "name": "Home",
        "url": "https://signalflow.live/"
      },
      {
        "@type": "WebPage",
        "name": "Dashboard",
        "url": "https://signalflow.live/custom-dashboard"
      },
      {
        "@type": "WebPage",
        "name": "Action Flow",
        "url": "https://signalflow.live/automation-builder"
      },
      {
        "@type": "WebPage",
        "name": "Alerts",
        "url": "https://signalflow.live/create-alerts"
      }
    ]
  };

  useEffect(() => {
    // Add structured data to head
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full z-50" role="banner">
      {/* Glassmorphism background */}
      <div className="absolute inset-0 backdrop-blur-xl bg-black/10 border-b border-white/10 shadow-lg" />
      
      <div className="relative flex justify-between items-center px-4 sm:px-6 py-3 max-w-7xl mx-auto">
        {/* Logo section */}
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="relative z-20"
        >
          <Link to="/" className="flex items-center space-x-3">
            <img
              src="/favicon.ico"
              alt="Signal Flow Logo"
              className="h-8 w-8 sm:h-10 sm:w-10 rounded-xl"
            />
            <span className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400">
            
            </span>
          </Link>
        </motion.div>

        {/* Desktop Navigation - Enhanced with conversion optimization */}
        <nav className="hidden md:flex items-center gap-8" role="navigation">
          <ul className="flex items-center gap-8">
            {navigationItems.map((item) => (
              <li key={item.path}>
                <button
                  onClick={() => handleNavClick(item.path, item.label)}
                  className={`transition duration-300 ease-in-out text-lg text-white hover:text-purple-400 ${
                    location.pathname === item.path ? "font-semibold" : "opacity-75"
                  }`}
                >
                  {item.label}
                </button>
              </li>
            ))}
            <li className="flex items-center">
              <NotificationDropdown />
            </li>
          </ul>

          {/* Enhanced CTA buttons for non-logged in users */}
          {!user ? (
            <div className="flex items-center gap-4">
              <button
                onClick={handleLoginClick}
                className="px-4 py-2 text-white hover:text-purple-400 transition-colors duration-300"
              >
                Login
              </button>
              <motion.button
                onClick={handleRegisterClick}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-6 py-2 bg-purple-600 rounded-full text-white font-medium hover:bg-purple-700 transition-colors duration-300 flex items-center gap-2"
              >
                Try for Free <RiArrowRightLine />
              </motion.button>
            </div>
          ) : (
            // User profile section - Enhanced with better visual hierarchy
            <div className="relative">
              <motion.button
                onClick={toggleDropdown}
                className="flex items-center gap-3 px-4 py-2 rounded-full border border-white/20 hover:border-purple-400/50 transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {user.avatar ? (
                  <img src={user.avatar} alt="" className="w-8 h-8 rounded-full" />
                ) : (
                  <div className="w-8 h-8 rounded-full bg-purple-600 flex items-center justify-center text-white font-medium">
                    {user.username[0].toUpperCase()}
                  </div>
                )}
                <span className="text-white">{user.username}</span>
              </motion.button>

              <AnimatePresence>
                {showDropdown && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-white/10"
                  >
                    <button
                      onClick={() => {
                        trackNavigation('Click_Settings');
                        navigate('/settings');
                        toggleDropdown();
                      }}
                      className="w-full px-4 py-3 text-left text-white hover:bg-purple-600/20 transition-colors duration-300 flex items-center gap-2"
                    >
                      <RiSettings3Line /> Settings
                    </button>
                    <button
                      onClick={handleLogout}
                      className="w-full px-4 py-3 text-left text-white hover:bg-purple-600/20 transition-colors duration-300 flex items-center gap-2"
                    >
                      <RiLogoutBoxRLine /> Logout
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </nav>

        {/* Mobile Menu Button - Enhanced with better accessibility */}
        <motion.button
          aria-expanded={showMobileMenu}
          aria-controls="mobile-menu"
          aria-label={showMobileMenu ? "Close menu" : "Open menu"}
          className="md:hidden relative z-20 p-2 rounded-xl border border-white/20 hover:border-purple-400/50 transition-all duration-300"
          onClick={() => {
            trackNavigation('Toggle_Mobile_Menu');
            toggleMobileMenu();
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <AnimatePresence mode="wait">
            {showMobileMenu ? (
              <RiCloseLine className="h-6 w-6 text-white" />
            ) : (
              <RiMenu3Line className="h-6 w-6 text-white" />
            )}
          </AnimatePresence>
        </motion.button>

        {/* Mobile Menu - Enhanced with better UX */}
        <AnimatePresence>
          {showMobileMenu && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/60 backdrop-blur-sm z-40 md:hidden"
                onClick={toggleMobileMenu}
              />
              
              <motion.div
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                exit={{ x: "-100%" }}
                transition={{ type: "spring", damping: 25 }}
                className="fixed top-0 left-0 h-full w-[280px] bg-gray-900 z-50 md:hidden"
              >
                {/* Mobile menu content - Enhanced with better visual hierarchy */}
                <div className="flex flex-col h-full">
                  {!user ? (
                    <div className="p-6 border-b border-white/10">
                      <motion.button
                        onClick={handleRegisterClick}
                        className="w-full px-6 py-3 bg-purple-600 rounded-full text-white font-medium mb-3 flex items-center justify-center gap-2"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Try for Free <RiArrowRightLine />
                      </motion.button>
                      <button
                        onClick={handleLoginClick}
                        className="w-full px-6 py-3 border border-white/20 rounded-full text-white hover:bg-white/5 transition-colors duration-300"
                      >
                        Login
                      </button>
                    </div>
                  ) : (
                    // User profile in mobile menu
                    <div className="p-6 border-b border-white/10">
                      <div className="flex items-center gap-4">
                        {user.avatar ? (
                          <img src={user.avatar} alt="" className="w-12 h-12 rounded-full" />
                        ) : (
                          <div className="w-12 h-12 rounded-full bg-purple-600 flex items-center justify-center text-white text-xl font-medium">
                            {user.username[0].toUpperCase()}
                          </div>
                        )}
                        <div>
                          <div className="text-white font-medium">{user.username}</div>
                          <div className="text-white/60 text-sm">Premium Member</div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Navigation items */}
                  <nav className="flex-1 p-6">
                    <ul className="space-y-3">
                      {navigationItems.map((item) => (
                        <motion.li key={item.path} whileTap={{ scale: 0.98 }}>
                          <button
                            onClick={() => handleNavClick(item.path, item.label)}
                            className={`w-full px-4 py-3 rounded-xl text-white transition-colors duration-300 ${
                              location.pathname === item.path
                                ? "bg-purple-600/20 font-medium"
                                : "hover:bg-white/5"
                            }`}
                          >
                            {item.label}
                          </button>
                        </motion.li>
                      ))}
                    </ul>
                  </nav>

                  {/* Bottom actions for logged-in users */}
                  {user && (
                    <div className="p-6 border-t border-white/10">
                      <button
                        onClick={() => {
                          trackNavigation('Click_Settings_Mobile');
                          navigate('/settings');
                          toggleMobileMenu();
                        }}
                        className="w-full px-4 py-3 rounded-xl text-white hover:bg-white/5 transition-colors duration-300 mb-3"
                      >
                        Settings
                      </button>
                      <button
                        onClick={handleLogout}
                        className="w-full px-4 py-3 rounded-xl text-white hover:bg-white/5 transition-colors duration-300"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Header;
