import React, { useState, useRef, useEffect } from 'react';
import { FaSpinner, FaCloudUploadAlt } from 'react-icons/fa';
import { uploadFilesWithProgress } from '../../../../api/storageService';
import FilePreview from './FilePreview';

interface FileUploadFieldProps {
  directoryKey: 'x_media' | 'profile_images' | 'attachments' | 'temp';
  maxFiles?: number;
  maxSizeInMB?: number;
  acceptedTypes?: string;
  onUploadComplete: (filePaths: string[]) => void;
  onError: (error: string) => void;
  className?: string;
  existingFiles?: string[];
  baseUrl?: string;
}

interface FileWithProgress {
  file?: File;
  progress: number;
  isUploading: boolean;
  path?: string;
  name: string;
}

export const FileUploadField: React.FC<FileUploadFieldProps> = ({
  directoryKey,
  maxFiles = 4,
  maxSizeInMB = 15,
  acceptedTypes = "image/*,video/*",
  onUploadComplete,
  onError,
  className,
  existingFiles = [],
  baseUrl = '',
}) => {
  const [fileItems, setFileItems] = useState<FileWithProgress[]>(() => 
    existingFiles.map(path => ({
      progress: 100,
      isUploading: false,
      path: baseUrl + path,
      name: path.split('/').pop() || path,
    }))
  );

  useEffect(() => {
    const updatedItems = existingFiles.map(path => ({
      progress: 100,
      isUploading: false,
      path: baseUrl + path,
      name: path.split('/').pop() || path,
    }));
    setFileItems(updatedItems);
  }, [existingFiles, baseUrl]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const isUploading = fileItems.some(item => item.isUploading);

  const validateFiles = (files: File[]): boolean => {
    for (const file of files) {
      const sizeInMB = file.size / (1024 * 1024);
      if (sizeInMB > maxSizeInMB) {
        onError(`File ${file.name} is too large. Maximum size is ${maxSizeInMB}MB`);
        return false;
      }
    }
    return true;
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const currentCount = fileItems.length;
    const remainingSlots = maxFiles - currentCount;
    
    if (remainingSlots <= 0) {
      onError(`Maximum ${maxFiles} files allowed`);
      e.target.value = '';
      return;
    }

    const newFiles = Array.from(files).slice(0, remainingSlots);
    
    if (!validateFiles(newFiles)) {
      e.target.value = '';
      return;
    }

    // Add new files to state immediately with preview URLs
    const newFileItems = newFiles.map(file => ({
      file,
      progress: 0,
      isUploading: true,
      name: file.name,
      path: URL.createObjectURL(file)
    }));

    setFileItems(prev => [...prev, ...newFileItems]);

    // Upload files
    try {
      const uploadPromises = newFiles.map(async (file, index) => {
        const response = await uploadFilesWithProgress(
          directoryKey,
          [file],
          (progress) => {
            setFileItems(current => {
              const updated = [...current];
              const currentIndex = current.length - newFiles.length + index;
              updated[currentIndex] = {
                ...updated[currentIndex],
                progress
              };
              return updated;
            });
          }
        );

        if (response.status === 'success') {
          setFileItems(current => {
            const updated = [...current];
            const currentIndex = current.length - newFiles.length + index;
            updated[currentIndex] = {
              ...updated[currentIndex],
              isUploading: false,
              path: baseUrl + response.file_urls[0]
            };
            console.log(response.file_urls[0]);
            return updated;
          });
          return response.file_urls[0];
        }
        throw new Error('Upload failed');
      });

      const newPaths = await Promise.all(uploadPromises);
      
      // Get all current paths (excluding uploading files) and add new paths
      const allPaths = fileItems
        .filter(item => !item.isUploading)
        .map(item => {
          if (item.path?.startsWith(baseUrl)) {
            return item.path.replace(baseUrl, '');
          }
          return null;
        })
        .filter(Boolean) as string[];

      onUploadComplete([...allPaths, ...newPaths]);
    } catch (error: any) {
      onError(error.message || 'Failed to upload files');
    } finally {
      e.target.value = '';
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const getRemainingCount = () => {
    return maxFiles - fileItems.length;
  };

  const handleRemoveFile = (index: number) => {
    setFileItems(prev => {
      const newItems = [...prev];
      newItems.splice(index, 1);
      
      // Extract paths without baseUrl for the callback
      const paths = newItems.map(item => {
        // If it's a server path, remove the baseUrl
        if (item.path?.startsWith(baseUrl)) {
          return item.path.replace(baseUrl, '');
        }
        // If it's a local path or undefined, skip it
        return null;
      })
      .filter(Boolean) as string[]; // Filter out null values
      
      onUploadComplete(paths);
      return newItems;
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4">
        {/* Hidden file input */}
        <input
          ref={fileInputRef}
          type="file"
          accept={acceptedTypes}
          multiple
          onChange={handleFileChange}
          className="hidden"
          disabled={isUploading || getRemainingCount() <= 0}
        />
        
        {/* Custom upload button */}
        <button
          onClick={handleButtonClick}
          disabled={isUploading || getRemainingCount() <= 0}
          className={`
            relative w-full px-4 py-3 
            bg-gray-800 hover:bg-[#3C2162]
            disabled:bg-[#2A1945] disabled:cursor-not-allowed
            text-white rounded-md transition-all duration-200
            flex items-center justify-center gap-2
            border border-[#8B5CF6] hover:border-[#A78BFA]
            ${className}
          `}
        >
          {isUploading ? (
            <FaSpinner className="animate-spin text-xl" />
          ) : (
            <FaCloudUploadAlt className="text-xl" />
          )}
          <span className="font-medium">
            {isUploading 
              ? "Uploading..." 
              : getRemainingCount() <= 0 
                ? "Maximum files reached" 
                : `Upload Media (${getRemainingCount()} remaining)`
            }
          </span>
        </button>

        {/* Upload info */}
        <div className="text-sm text-gray-300 space-y-1">
          <p className="flex items-center gap-2">
            <span>• Maximum {maxFiles} files</span>
            <span>• Up to {maxSizeInMB}MB each</span>
          </p>
          <p className="text-gray-400">
            Accepted formats: {acceptedTypes.replace('*', 'all').replace(/,/g, ', ')}
          </p>
        </div>
      </div>
      
      {/* File previews */}
      {fileItems.length > 0 && (
        <div className="mt-4">
          <FilePreview
            fileItems={fileItems}
            onRemove={handleRemoveFile}
          />
        </div>
      )}
    </div>
  );
};
