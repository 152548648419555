import React from 'react';
import { FaTimes } from 'react-icons/fa';
import CircularProgress from './CircularProgress';

interface FileWithProgress {
  file?: File;
  progress: number;
  isUploading: boolean;
  path?: string;
  name: string;
}

interface FilePreviewProps {
  fileItems: FileWithProgress[];
  onRemove: (index: number) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ 
  fileItems, 
  onRemove
}) => {
  const isImageFile = (path: string): boolean => {
    const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.bmp'];
    return extensions.some(ext => path.toLowerCase().endsWith(ext));
  };

  return (
    <div className="grid grid-cols-2 gap-2">
      {fileItems.map((item, index) => (
        <div key={index} className="relative group">
          {item.path && isImageFile(item.path) ? (
            <div className="relative">
              <img
                src={item.path}
                alt={item.name}
                className="w-full h-32 object-cover rounded-md"
              />
              {item.isUploading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 rounded-md">
                  <CircularProgress progress={item.progress} />
                </div>
              )}
            </div>
          ) : (
            <div className="relative w-full h-32 bg-gray-700 rounded-md flex items-center justify-center">
              <span className="text-sm text-white">{item.name}</span>
              {item.isUploading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 rounded-md">
                  <CircularProgress progress={item.progress} />
                </div>
              )}
            </div>
          )}
          <button
            onClick={() => onRemove(index)}
            className="absolute top-1 right-1 bg-red-500 rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
            disabled={item.isUploading}
          >
            <FaTimes className="text-white" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default FilePreview;
