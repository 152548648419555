// src/modals/NewXAccountModal.tsx
import React, { useState, useEffect } from "react";
import { XAccount } from "../../../types/flowTypes";
import { createXAccount, updateXAccount } from "../../../api/flowsService";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

type NewXAccountModalProps = {
  onClose: () => void;
  onAddAccount: (account: XAccount) => void;
  existingAccount?: XAccount;
};

const NewXAccountModal = ({ onClose, onAddAccount, existingAccount }: NewXAccountModalProps) => {
  const [formData, setFormData] = useState<Partial<XAccount>>({
    email: "",
    username: "",
    password: "",
  });
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (existingAccount) {
      setFormData({
        email: existingAccount.email || "",
        username: existingAccount.username || "",
        password: "",
      });
    }
  }, [existingAccount]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      let account;
      if (existingAccount?.id) {
        const updatedFields = {
          ...formData,
          ...(formData.password ? { password: formData.password } : {})
        };
        account = await updateXAccount(existingAccount.id, updatedFields);
      } else {
        account = await createXAccount(formData);
      }
      onAddAccount(account);
      onClose();
    } catch (err: any) {
      setError(err.message || "Failed to save account");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="p-6 bg-gray-800 rounded-lg shadow-lg w-96 relative">
        <h3 className="text-lg font-semibold mb-4">
          {existingAccount ? "Edit X Account" : "Add X Account"}
        </h3>
        
        {error && (
          <div className="mb-4 p-2 bg-red-500 text-white rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">Email Address:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md bg-gray-700"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2">Username:</label>
            <div className="relative">
              <span className="absolute left-2 top-2 text-gray-400">@</span>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                className="w-full p-2 pl-6 border border-gray-300 rounded-md bg-gray-700"
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block mb-2">Password:</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md bg-gray-700 pr-10"
                required={!existingAccount}
                placeholder={existingAccount ? "Leave blank to keep current password" : ""}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400 hover:text-white focus:outline-none transition-colors duration-200"
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5" />
                ) : (
                  <FaEye className="h-5 w-5" />
                )}
                <span className="sr-only">
                  {showPassword ? "Hide password" : "Show password"}
                </span>
              </button>
            </div>
          </div>

          <div className="flex justify-between mt-6">
            <button
              type="submit"
              disabled={isLoading}
              className="p-2 bg-green-500 text-white rounded-md disabled:bg-green-700 disabled:cursor-not-allowed"
            >
              {isLoading ? "Saving..." : (existingAccount ? "Update Account" : "Add Account")}
            </button>
            <button
              type="button"
              onClick={onClose}
              disabled={isLoading}
              className="p-2 bg-red-500 text-white rounded-md disabled:opacity-50"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewXAccountModal;