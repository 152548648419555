import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaPaperclip, FaFileImage, 
         FaFilePdf, FaFileWord, FaFileExcel, FaFileAlt, 
         FaDownload, FaExpand } from 'react-icons/fa';

export interface EmailData {
  id: string;
  sender?: string;
  recipient?: string;
  subject: string;
  body: string;
  html_content?: string;
  date: string;
  attachments: Array<{
    filename: string;
    url: string;
    content_type: string;
  }>;
}

const getFileIcon = (contentType: string) => {
  switch (contentType.toLowerCase()) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return <FaFileImage className="text-blue-400" />;
    case 'application/pdf':
      return <FaFilePdf className="text-red-400" />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <FaFileWord className="text-blue-600" />;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FaFileExcel className="text-green-600" />;
    default:
      return <FaFileAlt className="text-gray-400" />;
  }
};

const downloadFile = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Download failed:', error);
  }
};

const ImagePreview: React.FC<{ 
  url: string, 
  filename: string,
  onDownload: () => void 
}> = ({ url, filename, onDownload }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div className="relative group">
      <img
        src={url}
        alt={filename}
        className={`h-20 w-20 object-cover rounded-lg border border-gray-600/50 
                   ${isLoading ? 'blur-sm' : ''} ${error ? 'hidden' : ''}`}
        onLoad={() => setIsLoading(false)}
        onError={() => setError(true)}
      />
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800/50 rounded-lg">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-dodger-blue"></div>
        </div>
      )}
      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 
                    transition-opacity rounded-lg flex items-center justify-center gap-2">
        <button
          onClick={onDownload}
          className="p-1.5 rounded-full bg-gray-800/80 hover:bg-gray-700/80 
                     text-gray-200 transition-colors"
          title="Download"
        >
          <FaDownload className="w-4 h-4" />
        </button>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="p-1.5 rounded-full bg-gray-800/80 hover:bg-gray-700/80 
                     text-gray-200 transition-colors"
          title="Open full size"
        >
          <FaExpand className="w-4 h-4" />
        </a>
      </div>
    </div>
  );
};

export const EmailDisplay: React.FC<{ email: EmailData }> = ({ email }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 300;
  const isContentTruncated = email.body?.length > maxLength || false;

  const isImageFile = (contentType: string) => {
    return /^image\/(jpeg|png|gif|webp|svg\+xml)$/i.test(contentType);
  };

  return (
    <div className="bg-gray-800/50 rounded-lg p-4 space-y-3 border border-gray-700/50">
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <h3 className="font-medium text-gray-200">{email.subject}</h3>
          <p className="text-sm text-gray-400">{email.sender}</p>
        </div>
        <span className="text-xs text-gray-500">
          {new Date(email.date).toLocaleString()}
        </span>
      </div>

      <div className="text-sm text-gray-300">
        <div className={`prose prose-invert max-w-none ${!isExpanded && 'line-clamp-3'}`}>
          {isExpanded ? email.body : email.body?.slice(0, maxLength)}
          {!isExpanded && isContentTruncated && '...'}
        </div>
        {isContentTruncated && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-dodger-blue hover:text-dodger-blue/80 text-sm mt-1 flex items-center gap-1"
          >
            {isExpanded ? (
              <>Show less <FaChevronUp className="text-xs" /></>
            ) : (
              <>Show more <FaChevronDown className="text-xs" /></>
            )}
          </button>
        )}
      </div>

      {email.attachments?.length > 0 && (
        <div className="border-t border-gray-700/50 pt-3">
          <div className="flex items-center gap-2 text-sm text-gray-400 mb-2">
            <FaPaperclip />
            <span>{email.attachments.length} attachment{email.attachments.length !== 1 ? 's' : ''}</span>
          </div>
          <div className="flex flex-wrap gap-2">
            {email.attachments.map((attachment, index) => (
              isImageFile(attachment.content_type) ? (
                <ImagePreview
                  key={index}
                  url={attachment.url}
                  filename={attachment.filename}
                  onDownload={() => downloadFile(attachment.url, attachment.filename)}
                />
              ) : (
                <button
                  key={index}
                  onClick={() => downloadFile(attachment.url, attachment.filename)}
                  className="flex items-center gap-2 px-3 py-2 rounded-lg bg-gray-700/30 
                           hover:bg-gray-700/50 transition-colors border border-gray-600/50"
                >
                  {getFileIcon(attachment.content_type)}
                  <div className="text-left">
                    <div className="text-sm text-gray-300 truncate max-w-[200px]">
                      {attachment.filename}
                    </div>
                    <div className="text-xs text-gray-500">
                      {attachment.content_type.split('/')[1].toUpperCase()}
                    </div>
                  </div>
                </button>
              )
            ))}
          </div>
        </div>
      )}
    </div>
  );
}; 