import { Block } from '../../../types/flowTypes';
import { SubscriptionData, UsageData } from '../../../types/subscriptionTypes';

interface ValidationError {
  blockId: string;
  field: string;
  message: string;
  type?: 'subscription' | 'validation';
}

export const validateTimeTriggerBlock = (block: Block, usage: UsageData | null): ValidationError[] => {
  const errors: ValidationError[] = [];
  const data = block.data || {};

  if (!data.trigger_type) {
    errors.push({
      blockId: block.id!,
      field: 'trigger_type',
      message: 'Trigger type must be selected'
    });
    return errors;
  }

  switch (data.trigger_type) {
    case 'specific_dates':
      if (!data.specific_dates?.length) {
        errors.push({
          blockId: block.id!,
          field: 'specific_dates',
          message: 'At least one date must be selected'
        });
      }
      break;

    case 'times_of_day':
      if (!data.times_of_day?.length) {
        errors.push({
          blockId: block.id!,
          field: 'times_of_day',
          message: 'At least one time must be selected'
        });
      }
      break;

    case 'delay':
      if (!data.delay) {
        errors.push({
          blockId: block.id!,
          field: 'delay',
          message: 'Interval value is required'
        });
      } else {
        const minDelay = usage?.limits?.trigger_delay_minutes || 30;
        if (Number(data.delay) < minDelay) {
          errors.push({
            blockId: block.id!,
            field: 'delay',
            message: `Minimum interval is ${minDelay} minutes on your plan`,
            type: 'subscription'
          });
        }
      }
      break;
  }

  return errors;
};

export const validateEmailCheckBlock = (block: Block): ValidationError[] => {
  const errors: ValidationError[] = [];
  const data = block.data || {};

  if (!data.email) {
    errors.push({
      blockId: block.id!,
      field: 'email',
      message: 'Email address is required'
    });
  }

  return errors;
};

export const validateSendEmailBlock = (block: Block): ValidationError[] => {
  const errors: ValidationError[] = [];
  const data = block.data || {};

  if (!data.sender) {
    errors.push({
      blockId: block.id!,
      field: 'sender',
      message: 'Sender email is required'
    });
  }

  // if (!data.content && !data.html_content) {
  //   errors.push({
  //     blockId: block.id!,
  //     field: 'content',
  //     message: 'Email content is required'
  //   });
  // }

  return errors;
};

export const validateYouTubeShortsBlock = (block: Block): ValidationError[] => {
  const errors: ValidationError[] = [];
  const data = block.data || {};

  if (!data.channel_name) {
    errors.push({
      blockId: block.id!,
      field: 'credentialId',
      message: 'YouTube account is required'
    });
  }
  
  return errors;
};

export const validateVideoGeneratorBlock = (block: Block): ValidationError[] => {
  const errors: ValidationError[] = [];
  const data = block.data || {};

  if (!data.credentialId) {
    errors.push({
      blockId: block.id!,
      field: 'credentialId',
      message: 'InVideo account is required'
    });
  }

  return errors;
}; 